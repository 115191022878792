.about {
    background-color: rgba(0, 0, 0, 0.986);
    display: flex;
}

.container {
    margin: 0 auto;
    max-width: 107rem;
    padding: 0 4rem;
}

.aboutTextPart {
    display: flex;
    align-items: center;
}

.download {
    color: white;
    background-color: blue;
}

.download:hover {
    background-color: transparent;
    color: white;
    border: 1px solid blue;
}

.arrow-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-description-indented {
    margin-left: 4%;
}

.aboutImagePart img {
    max-width: 70%;
    max-height: auto;
}

.mySkills {
    align-items: center;
    color: #6e6e6e;
    display: flex;
    font-size: 1.7rem;
}

.mySkills p {
    border-right: 2px solid #2b2b2b;
    color: #6e6e6e;
    font-weight: 600;
    margin-right: 7rem;
    padding-right: 3%;
}

.icon-container {
    margin-right: 10px;
}

.icon-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; 
    align-items: center; 
}

.rounded-image {
    border: 2px solid #007bff;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%; 
    transition: all 1s ease-in-out;
    animation: morphBorder 8s ease-in-out infinite; 
}

@keyframes morphBorder {
    0% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
      border-color: #6e6e6e; /* Initial border color */
    }
    50% {
      border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%; /* Morph border radius */
      border-color: #6e6e6e; /* New border color */
    }
    100% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
      border-color: #6e6e6e; /* Back to initial border color */
    }
  }
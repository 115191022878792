.home {
    background-image: url('../static/1Kte-ezgif.com-speed.gif');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeTextPart {
    display: flex;
    text-align: center;
}

.homeTextPart h3 {
    color: rgb(255, 255, 255) !important;
}

.icons a {
    font-size: 20px;
    padding: 0px 10px;
}

.icons a:hover {
    opacity: 80%;
}

@media screen and (max-width: 768px) {
    .home {
        height: auto;
    }
    .homeTextPart {
        text-align: center;
        padding-top: 80px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    animation-delay: 2.5s;
}

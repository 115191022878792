.navbar {
    background-color: black !important;
}

.navbar li {
    padding: 0px 20px;
}

.navbar li {
    color: white !important;
    font-weight: bold;
}

.navbar li a:hover {
    opacity: 80%;
}
.navbar-nav {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.navbar-nav .nav-item {
    padding: 0 20px;
}

.custom-toggler {
    margin-left: -40px;
}